import React from 'react'
import { Link } from 'gatsby'
import MainMenu from "./MainMenu"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

class HeaderC extends React.Component {

    state = {
        menuActive: false,
    }

    render() {
        return (
            <div>

                <div className="fixed w-full h-24 px-8 md:px-12 z-40">
                    <div className="grid grid-cols-8 gap-8 h-full flex items-center">
                        <div></div>
                        <div className="text-center title col-span-6"><Link to="/">SIRJE RUNGE</Link></div>
                        <div className="flex flex-row-reverse">
                            <div className={this.state.menuActive ? "hidden" : "inline-block"}>
                                <button
                                    className="btn-sm btn-none"
                                    onClick={() => this.setState({ menuActive: !this.state.menuActive })}>
                                    <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="36" height="28" />
                                        <line y1="1" x2="36" y2="1" stroke="black" stroke-width="2" />
                                        <line y1="27" x2="36" y2="27" stroke="black" stroke-width="2" />
                                        <line y1="14" x2="36" y2="14" stroke="black" stroke-width="2" />
                                    </svg>

                                </button>
                            </div>

                            <div className={this.state.menuActive ? "inline-block" : "hidden"}>
                                <button
                                    className="btn-sm btn-none"
                                    onClick={() => this.setState({ menuActive: !this.state.menuActive })}>
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <line x1="1.72859" y1="0.730393" x2="27.1844" y2="26.1862" stroke="black" stroke-width="2" />
                                            <line x1="1.72844" y1="26.1835" x2="27.1843" y2="0.727675" stroke="black" stroke-width="2" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="28" height="28" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={this.state.menuActive ? "block" : "hidden"}>
                    <MainMenu />


                </div>


                <div className="h-24"></div>

            </div>
        )
    }
}

export default HeaderC