import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/SEO"
import Header from "../../components/HeaderC"
import PageNav from "../../components/PageNav"

const GreatLove = (props) => {

  const intl = useIntl()

  return (
    <div>
      <Seo
        title={intl.formatMessage({ id: "greatlove.title.seo" })}
      />
      <Header />

      <div className="">

        <div className="sir-breadcrumbs">
          <Link class="text-sir-black-50" to="/"><FormattedMessage id="global.sir" /></Link>
          <span class="text-sir-black-25 mx-1">/</span>
          <FormattedMessage id="global.menu.greatlove" />
        </div>


        <div className="bg-black text-white">
          <div className="px-10 pt-16">
            <div className="mx-auto md:w-1/2">
              <div className="title-l uppercase">
                <div className="text-left"><FormattedMessage id="global.great" /><br /> <FormattedMessage id="global.love" /></div>
                <div className="text-right"><FormattedMessage id="global.beautiful" /><br /> <FormattedMessage id="global.rotting" /></div>
              </div>
            </div>
          </div>

          <div className="sir-section sir-layout-2-3 body-xl">
            <p>
              <FormattedMessage id="greatlove.quote" />
            </p>
            <p className="text-right body-l mt-4">
              <FormattedMessage id="global.sirjerunge" /> (<FormattedMessage id="global.sir" />)
            </p>
          </div>

        </div>


     

        <div className="sir-section sir-layout-2-3">
  
          <p className="body-l">
            <FormattedMessage id="greatlove.press.text2" />
          </p>

        </div>

        


        <h2 className="sir-section-side sir-layout-2-3 title mb-8">
          <FormattedMessage id="greatlove.subtitle4" /></h2>


        <div className="mb-16">
       
          <iframe className="w-full h-144" src="https://www.youtube.com/embed/live_stream?channel=UCFiW73X1BXCP9ikZOLxEEDQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>

        <div className="sir-section-side sir-layout-2-3 body-l">
  
        <div className="w-full flex justify-start">
          <div className="md:w-3/4 mb-16">
            <FormattedMessage id="greatlove.press.text3" />
          </div>
        </div>

        <div className="w-full flex justify-end">
          <div className="md:w-3/4 mb-16">
            <FormattedMessage id="greatlove.press.text4" />
          </div>
        </div>

        <div className="w-full flex justify-start">
          <div className="md:w-3/4 mb-16">
            <FormattedMessage id="greatlove.press.text5" />
          </div>
        </div>

        <div className="w-full flex justify-end">
          <div className="md:w-3/4 mb-16">
            <FormattedMessage id="greatlove.press.text1" /><span> </span>
            <a href="/great-love/texts"><span className="link"><FormattedMessage id="greatlove.press.text1.cta" /></span> &rarr;</a>
          </div>
        </div>
 
      

        </div>

        <div className="bg-black">
          <h2 className="title text-white sir-section sir-layout-2-3"><FormattedMessage id="greatlove.subtitle3" /></h2>
          <a href="https://goo.gl/maps/4fcy9UwJHLSgMXWR6" className="w-full" target="_blank" rel="noreferrer">
            <StaticImage className="w-full" src="../../images/site/sir-map.png" alt="Light 1" />
          </a>
        </div>

        <div className="sir-section sir-layout-2-3 body-l">
          <h2 className="title mx-auto mb-8"><FormattedMessage id="greatlove.subtitle2" /></h2>
          <p className="body-l mb-8"><FormattedMessage id="greatlove.text2" /></p>
          <ul>
            <li className="mb-1"><a href="https://goo.gl/maps/4fcy9UwJHLSgMXWR6" target="_blank"  rel="noreferrer"><span className="link"><FormattedMessage id="greatlove.directions" /></span> &rarr;</a></li>
            <li><a href="https://evm.ee/est/piletiinfo" target="_blank"  rel="noreferrer"><span className="link"><FormattedMessage id="greatlove.visitorinfo" /></span> &rarr;</a></li>
          </ul>
        </div>
    

      </div>
      <PageNav />
    </div>
  )
}

export default GreatLove;